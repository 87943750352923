import { Alert, AlertDescription, AlertIcon, Box, Button, Card, CardBody, Image, Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useCallback, useState } from "react";

type Props = {
  userId: string;
  branch: "jingumae" | "hamamatsu";
  table: string;
};

const Component: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleCheckin = useCallback(async () => {
    try {
      setLoading(true);
      setError(false);
      await axios.post(`https://${process.env.NEXT_PUBLIC_API_ENDPOINT}/main/checkin`, {
        lineId: props.userId,
        branch: props.branch,
        table: props.table,
      });

      // 公式Line のトーク画面を開く
      window.location.href = `https://line.me/R/oaMessage/${
        props.branch === "jingumae"
          ? process.env.NEXT_PUBLIC_LINE_ID
          : props.branch === "hamamatsu"
          ? process.env.NEXT_PUBLIC_HAMAMATSU_LINE_ID
          : ""
      }`;
      setLoading(false);
    } catch {
      setError(true);
      setLoading(false);
    }
  }, [props.branch, props.table, props.userId]);

  return (
    <Box maxWidth="lg" m="0 auto" color="black">
      <Image src="/logo.png" alt="ロゴ画像" boxSize="3xs" m="0 auto" />
      <Card>
        <CardBody>
          <Text>
            いらっしゃいませ。
            <br />
            本日は Shisha Cafe tone. へお越しくださり、誠にありがとうございます。
            <br />
            <br />
            お手数ではございますが、下記のボタンを押してチェックインを行ってください。
          </Text>

          <Button variant="primary-on-accent" mt="8" onClick={handleCheckin} isLoading={loading}>
            チェックイン
          </Button>
          {error && (
            <Alert status="error" borderRadius="md" mt="2" textStyle="sm">
              <AlertIcon />
              <AlertDescription mt="2">
                予期せぬエラーが発生しました。
                <br />
                再度お試しいただいて解決しない場合は、お手数ですが店員までお声がけください。
              </AlertDescription>
            </Alert>
          )}
          <Text mt="3" textStyle="sm" color="subtle">
            チェックイン完了後、Line の画面が開きます。
          </Text>
        </CardBody>
      </Card>
    </Box>
  );
};

export default Component;
