import { BoxProps, Divider, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { StepCircle } from "./StepCircle";

interface StepProps extends BoxProps {
  title: string;
  description: string;
  isCompleted: boolean;
  isActive: boolean;
  isLastStep: boolean;
  isFirstStep: boolean;
}

export const Step = (props: StepProps) => {
  // const { isActive, isCompleted, isLastStep, isFirstStep, title, description, ...stackProps } = props;
  const { isActive, isCompleted, isLastStep, isFirstStep, title, ...stackProps } = props;
  const isMobile = useBreakpointValue({
    //  base: true,
    base: false,
    md: false,
  });

  const orientation = useBreakpointValue<"horizontal" | "vertical">({
    // base: "vertical",
    base: "horizontal",
    md: "horizontal",
  });

  return (
    <Stack
      spacing="4"
      direction={{
        // base: "row",
        base: "column",
        md: "column",
      }}
      flex="1"
      {...stackProps}
    >
      <Stack
        spacing="0"
        align="center"
        direction={{
          // base: "column",
          base: "row",
          md: "row",
        }}
      >
        <Divider
          display={isMobile ? "none" : "initial"}
          orientation={orientation}
          borderWidth="1px"
          borderColor={isFirstStep ? "transparent" : isCompleted || isActive ? "accent" : "inherit"}
        />
        <StepCircle isActive={isActive} isCompleted={isCompleted} />
        <Divider
          orientation={orientation}
          borderWidth="1px"
          borderColor={isCompleted ? "accent" : isLastStep ? "transparent" : "inherit"}
        />
      </Stack>
      <Stack
        spacing="0.5"
        pb={isMobile && !isLastStep ? "8" : "0"}
        align={{
          //  base: "start",
          base: "center",
          md: "center",
        }}
      >
        <Text
          color={props.isActive ? "emphasized" : "subtle"}
          fontWeight={props.isActive ? "medium" : "medium"}
          fontSize={props.isActive ? "14px" : "12px"}
          size="lg"
          textAlign="center"
        >
          {
            (title.split("\n") as React.ReactNode[])
              // joinはReactNodeに対して使えないのでworkaround
              .reduce((prev, curr, i) => [prev, <br key={i} />, curr]) as JSX.Element
          }
        </Text>
        {/* {props.isActive && <Text color="muted">{description}</Text>}// TODO
        ここをもうちょいまともに */}
      </Stack>
    </Stack>
  );
};
