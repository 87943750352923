import { Box, Button, FormControl, FormHelperText, FormLabel, Input, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  confirmNickname: React.FormEventHandler<HTMLDivElement> & React.FormEventHandler<HTMLFormElement>;
  loading: boolean;
  noLine: boolean;
};

const Component: React.FC<Props> = (props) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Box as="form" bg="bg-surface" boxShadow="sm" mt="4" onSubmit={props.confirmNickname}>
        <Stack px="4" py="5" spacing="5">
          <FormControl isRequired>
            <FormLabel>{t("step3Question")}</FormLabel>
            <Input onChange={props.handleChange} isRequired />
            {!props.noLine && <FormHelperText>{t("step3Desc")}</FormHelperText>}
          </FormControl>
          <Button
            variant="primary"
            type="submit"
            height="16"
            textAlign="center"
            isLoading={props.loading}
            whiteSpace="pre-line"
          >
            {t("step3Confirm")}
          </Button>
          {!props.noLine && (
            <Text mt="3" textStyle="sm" color="subtle">
              {t("step3ConfirmDesc")}
            </Text>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default Component;
