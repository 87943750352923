import sleep from "@/lib/sleep";
import axios from "axios";
import { cache } from "react";

export const getLineInfo = cache(async (noLine: boolean, branch: "jingumae" | "hamamatsu") => {
  if (noLine) {
    return { isFirstCheckIn: true, userId: "" };
  }

  // dynamic import をしないと、 output: export 指定しつつ next build した時に、
  //  Generating static pages (0/4)ReferenceError: window is not defined
  // というエラーが発生する
  const liff = (await import("@line/liff")).default;
  const LiffMockPlugin = (await import("@line/liff-mock")).default;

  const useMock = process.env.NODE_ENV === "development";
  if (useMock) {
    liff.use(new LiffMockPlugin());
    (liff as any).$mock.set((p: any) => ({
      ...p,
      getProfile: { displayName: "Cony", userId: "1111111" },
    }));
  }
  console.log("before info");
  await liff.init({
    liffId:
      branch === "jingumae"
        ? process.env.NEXT_PUBLIC_LIFF_ID
        : branch === "hamamatsu"
        ? process.env.NEXT_PUBLIC_HAMAMATSU_LIFF_ID
        : "",
    withLoginOnExternalBrowser: false,
    mock: useMock,
  } as any);
  console.log("after info");
  console.log("before ready");

  await liff.ready;
  console.log("after ready");

  console.log("before isLoggedIn");
  if (!liff.isLoggedIn()) {
    console.log("inside isLoggedIn");
    // window.location.href には、query string が特殊な場合、query string が含まれなくなる
    const { origin, pathname, search } = globalThis.window.location;
    // liff.login メソッドが発火してから自動的にログインのためにリダイレクトされるが、一定ラグがある。
    // liff.login の後、十分長く待ってから、それでも遷移しない場合は throw に到達し、エラーが吐かれる
    liff.login({ redirectUri: `${origin}${pathname}${search}` });
    await sleep(60000);
    console.log("after isLoggedIn");
    throw new Error("login takes too long");
  } else {
    console.log("before getProfile");
    const { userId } = await liff.getProfile();
    console.log("after getProfile");
    const { isFirstCheckIn } = (
      await axios.get(
        `https://${process.env.NEXT_PUBLIC_API_ENDPOINT}/main/check-if-first-checkin?lineUserId=${userId}`
      )
    ).data;
    return { isFirstCheckIn, userId: userId };
  }
});
