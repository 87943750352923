import { Box, Button, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  completeCheckin: React.FormEventHandler<HTMLDivElement> & React.FormEventHandler<HTMLFormElement>;
  loading: boolean;
  noLine: boolean;
};

const Component: React.FC<Props> = (props) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Box as="form" bg="bg-surface" boxShadow="sm" mt="4" onSubmit={props.completeCheckin}>
        <Stack px="4" py="5" spacing="5">
          <Button
            variant="primary"
            type="submit"
            height="16"
            textAlign="center"
            isLoading={props.loading}
            whiteSpace="pre-line"
          >
            {t("completeCheckin")}
          </Button>
          {!props.noLine && (
            <Text mt="3" textStyle="sm" color="subtle">
              {t("completeCheckinDesc")}
            </Text>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default Component;
