"use client";

import CSR from "@/components/CSR";
import { Alert, AlertIcon, AlertTitle, Box, ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/nextjs";
import Fonts from "./fonts";
import { theme } from "./theme";

export default function GlobalError({
  error,
}: // reset,
{
  error: Error;
  reset: () => void;
}) {
  console.log("catch", error);
  console.log("stack", error.stack);
  Sentry.captureException(error, { extra: { liffId: process.env.NEXT_PUBLIC_LIFF_ID } });

  return (
    <html>
      <head></head>
      <body>
        <ChakraProvider theme={theme}>
          <Fonts />
          <CSR>
            <Box bgColor="gray.100" minH="100vh" p="10" whiteSpace="pre-line">
              <Alert
                status="error"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                <AlertIcon />
                <AlertTitle mt={4} mb={1}>
                  予期せぬエラーが発生しました。再度お試しください。
                </AlertTitle>
              </Alert>
            </Box>
          </CSR>
        </ChakraProvider>
      </body>
    </html>
  );
}
