import RadioCardGroup from "@/chakra-ui-pro/RadioGroup/App";
import { Box, FormControl, FormLabel, Stack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  handleSelect: (val: string) => void;
};

const Component: React.FC<Props> = (props) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Box as="form" bg="bg-surface" boxShadow="sm" mt="4">
        <Stack px="4" py="5">
          <FormControl isRequired>
            <FormLabel>{t("step2Question")}</FormLabel>
            <RadioCardGroup
              onChange={props.handleSelect}
              options={[
                { val: "全く吸ったことがない", header: t("step2Option.1") },
                { val: "過去数回ほど", header: t("step2Option.2") },
                { val: "3ヶ月に1回ほど", header: t("step2Option.3") },
                { val: "月に1回ほど", header: t("step2Option.4") },
                { val: "月に2回ほど", header: t("step2Option.5") },
                { val: "月に3回ほど", header: t("step2Option.6") },
              ]}
            />
          </FormControl>
        </Stack>
      </Box>
    </>
  );
};

export default Component;
