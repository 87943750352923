import RadioCardGroup from "@/chakra-ui-pro/RadioGroup/App";
import { Box, FormControl, FormLabel, Stack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  handleSelect: (val: string) => void;
};

const Component: React.FC<Props> = (props) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Box as="form" bg="bg-surface" boxShadow="sm" mt="4">
        <Stack px="4" py="5">
          <FormControl isRequired>
            <FormLabel>{t("step1Question")}</FormLabel>
            <RadioCardGroup
              onChange={props.handleSelect}
              options={[
                { val: "知人の紹介", header: t("step1Option.1") },
                { val: "GoogleMap", header: t("step1Option.2") },
                { val: "RETRIP・食べログ・Hotpepper", header: t("step1Option.3") },
                { val: "Google 検索", header: t("step1Option.4") },
                { val: "Instagram", header: t("step1Option.5") },
                { val: "Twitter", header: t("step1Option.6") },
                { val: "TikTok", header: t("step1Option.7") },
                { val: "通りすがり", header: t("step1Option.8") },
                { val: "キャンペーンを知って", header: t("step1Option.9") },
              ]}
            />
          </FormControl>
        </Stack>
      </Box>
    </>
  );
};

export default Component;
