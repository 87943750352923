import { theme as proTheme } from "@chakra-ui/pro-theme";
import { extendTheme, theme as baseTheme } from "@chakra-ui/react";

export const theme = extendTheme(
  {
    colors: { ...baseTheme.colors, brand: baseTheme.colors.blue },
    fonts: {
      heading: `"m-plus-1p",source-han-sans-japanese, sans-serif`,
      body: `"m-plus-1p",source-han-sans-japanese, sans-serif`,
    },
  },
  proTheme
);
