import { Box, Container, Stack } from "@chakra-ui/react";
import { Step } from "./Step";

type Props = {
  steps: { title: string; description: string }[];
  currentStep: number;
};

const StepsWithCirclesAndText: React.FC<Props> = ({ steps, currentStep }) => {
  return (
    // <Box bg="bg-surface">
    <Box>
      <Container py={{ base: "4", md: "8" }}>
        <Stack
          spacing="0"
          direction={{
            //  base: "column",
            base: "row",
            md: "row",
          }}
        >
          {steps.map((step, id) => (
            <Step
              key={id}
              cursor="pointer"
              title={step.title}
              description={step.description}
              isActive={currentStep === id}
              isCompleted={currentStep > id}
              isFirstStep={id === 0}
              isLastStep={steps.length === id + 1}
            />
          ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default StepsWithCirclesAndText;
