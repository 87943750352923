import i18n from "i18next";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpBackend)
  .init<HttpBackendOptions>({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    lng: "ja", // default lang
    ns: ["common"], // default ns
    defaultNS: "common",
    fallbackLng: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
