import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Card,
  CardBody,
  Fade,
  Image,
  LinkOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import CompleteCheckin from "./CompleteCheckin";

import { useStep } from "@/chakra-ui-pro/Steps/useStep";
import sleep from "@/lib/sleep";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { HiArrowLeft } from "react-icons/hi";
import HowToKnow from "./HowToKnow";

type Props = {
  table: string;
  branch: "jingumae" | "hamamatsu";
  userId: string;
  noLine: boolean;
};

const Component: React.FC<Props> = (props) => {
  const { t } = useTranslation("common");

  const steps = useMemo(
    () => [
      {
        title: t("step1"),
        description: "",
      },
      {
        title: t("step3"),
        description: "",
      },
    ],
    [t]
  );

  const [startProcess, setStartProcess] = useState(false);
  const [endProcess, setEndProcess] = useState(false);
  const [currentStep, { canGoToPrevStep, goToPrevStep, goToNextStep }] = useStep({
    maxStep: steps.length,
    initialStep: 0,
    hookBeforeStepChange: async () => {
      setShowFade(false);
      await sleep(200);
      setShowFade(true);
      window.scrollTo({
        top: 0,
      });
    },
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [showFade, setShowFade] = useState(true);

  const [recognitionMethod, setRecognitionMethod] = useState("");
  const handleRecognitionMethodSelect = useCallback(
    async (val: string) => {
      await sleep(1000);
      setRecognitionMethod(val);
      goToNextStep();
    },
    [goToNextStep]
  );

  const completeCheckin = useCallback(
    async (e: React.FormEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        setError(false);
        await axios.post(`https://${process.env.NEXT_PUBLIC_API_ENDPOINT}/main/first-checkin`, {
          lineId: props.userId,
          branch: props.branch,
          table: props.table,
          nickname: "", // empty
          recognitionMethod,
          shishaExperience: "", // empty,
        });
        if (!props.noLine) {
          // noLIne でない場合、公式Line のトーク画面を開く
          window.location.href = `https://line.me/R/oaMessage/${
            props.branch === "jingumae"
              ? process.env.NEXT_PUBLIC_LINE_ID
              : props.branch === "hamamatsu"
              ? process.env.NEXT_PUBLIC_HAMAMATSU_LINE_ID
              : ""
          }/`;
        } else {
          setEndProcess(true);
        }
        setLoading(false);
      } catch {
        setError(true);
        setLoading(false);
      }
    },
    [props.branch, props.noLine, props.table, props.userId, recognitionMethod]
  );

  return (
    <Box maxWidth="lg" m="0 auto" color="black">
      <Image src="/logo.png" alt="ロゴ画像" boxSize={startProcess && !endProcess ? "20" : "40"} m="0 auto" />
      {endProcess ? (
        <Alert
          status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          borderRadius="md"
          mt="6"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            CheckIn is complete!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Thank you for taking the time to complete the survey. <br />
            <LinkOverlay href="https://menu.shisha-tone.com/en/">
              <Button variant="primary" mt="4">
                Open the menu
              </Button>
            </LinkOverlay>
            <Spacer />
          </AlertDescription>
        </Alert>
      ) : startProcess ? (
        <>
          <Fade in={showFade}>
            {currentStep === 0 ? (
              <HowToKnow handleSelect={handleRecognitionMethodSelect} />
            ) : currentStep === 1 ? (
              <CompleteCheckin completeCheckin={completeCheckin} loading={loading} noLine={props.noLine} />
            ) : (
              ""
            )}
          </Fade>
          {error && (
            <Alert status="error" borderRadius="md" mt="2" textStyle="sm">
              <AlertIcon />
              <AlertDescription mt="2">{t("unexpectedError")}</AlertDescription>
            </Alert>
          )}
          {canGoToPrevStep && (
            <Button
              leftIcon={<HiArrowLeft />}
              mt="5"
              variant="ghost"
              onClick={async () => {
                goToPrevStep();
              }}
              isLoading={loading}
            >
              {t("backToPrev")}
            </Button>
          )}
        </>
      ) : (
        <Card>
          <CardBody>
            <Text>{t("welcome")}</Text>
            <Button
              variant="primary-on-accent"
              mt="6"
              onClick={() => {
                setStartProcess(true);
              }}
            >
              {t("startCheckin")}
            </Button>
          </CardBody>
        </Card>
      )}
    </Box>
  );
};

export default Component;
