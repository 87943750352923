import { ChangeEvent, useCallback, useState } from "react";

export function useTextInput(initialValue: string) {
  const [value, setValue] = useState(initialValue ?? undefined);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);
  return { inputProps: { value: value, onChange: handleChange }, setValue };
}
