import { getLineInfo } from "@/api/getLineInfo";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Image } from "@chakra-ui/react";
import { useSearchParams } from "next/navigation";
import React, { use, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FirstCheckin from "./FirstCheckin";
import FirstCheckinJingumae from "./FirstCheckinJingumae";
import NormalCheckin from "./NormalCheckin";

// type Props = {};

const Component: React.FC = () => {
  const { t, i18n } = useTranslation("common");

  const searchParams = useSearchParams();

  const noLine = searchParams.get("noLine") === "true";
  useEffect(() => {
    if (noLine) {
      i18n.changeLanguage("en");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noLine]);

  // LIFF では、一時リダイレクト時は、state にクエリが urlencode された状態で入っている
  const branchFromStateParam = new URLSearchParams(
    decodeURIComponent(searchParams.get("liff.state") ?? "").split("?")[1],
  ).get("branch");

  const branch = searchParams.get("branch") as "jingumae" | "hamamatsu" | null;

  // branch クエリから指定された方を優先
  const lineInfo = use(getLineInfo(noLine, branch || (branchFromStateParam as "jingumae" | "hamamatsu") || "jingumae"));

  // Liff の URL から、一時的に table クエリなどが加工された状態の URL でアクセスがくる。
  // その URL にアクセスが来たあと、liff.init を実行することで、そのクエリが返ってくる.
  // その状態でコンポーネントに再 render が走れば、修正された状態のクエリから table を取ってこれるはず。
  const table = searchParams.get("table");

  if (!table) {
    return (
      <Box maxWidth="lg" m="0 auto" color="black">
        <Image src="/logo.png" alt="ロゴ画像" boxSize="3xs" m="0 auto" />
        <Alert status="error" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
          <AlertIcon />
          <AlertTitle mt={4} mb={1}>
            {t("invalidURL")}
          </AlertTitle>
          <AlertDescription>{t("invalidURLDesc")}</AlertDescription>
        </Alert>
      </Box>
    );
  }

  if (lineInfo.isFirstCheckIn) {
    if (branch === "hamamatsu") {
      return <FirstCheckin userId={lineInfo.userId} table={table} noLine={noLine} branch="hamamatsu" />;
    }
    return <FirstCheckinJingumae userId={lineInfo.userId} table={table} noLine={noLine} branch="jingumae" />;
  }

  return <NormalCheckin userId={lineInfo.userId} table={table} branch={branch ?? "jingumae"} />;
};

export default Component;
