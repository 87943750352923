import { Box, Container, Text } from "@chakra-ui/react";
import { RadioCard, RadioCardGroup } from "./RadioCardGroup";

type Props = {
  options: { val: string; header: string; description?: string }[];
  onChange: (val: string) => void;
};

const App: React.FC<Props> = ({ options, onChange }) => {
  return (
    <Box as="section" bg="bg-surface" py={{ base: "4", md: "8" }}>
      <Container maxW="lg">
        <RadioCardGroup defaultValue="one" spacing="3" onChange={onChange}>
          {options.map(({ val, header, description }) => (
            <RadioCard key={val} value={val}>
              <Text color="emphasized" fontWeight="medium" fontSize="sm">
                {header}
              </Text>
              {description && (
                <Text color="muted" fontSize="sm">
                  {description}
                </Text>
              )}
            </RadioCard>
          ))}
        </RadioCardGroup>
      </Container>
    </Box>
  );
};
export default App;
