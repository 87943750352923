import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
        // @font-face{
        //     font-family: adobe-caslon-pro, serif;
        //     font-weight: 400;
        //     font-style: normal;
        // }
        // @font-face{
        //     font-family: adobe-caslon-pro, serif;
        //     font-weight: 700;
        //     font-style: normal;
        // }
        // @font-face{
        //     font-family: adobe-caslon-pro, serif;
        //     font-weight: 600;
        //     font-style: normal;
        // }
        // @font-face{
        //     font-family: HiraginoMincho;
        //     src:
        //         url('/fonts/hiragino-mincho-pro-w3.otf') format('opentype');
        //     font-weight: normal;
        //     font-style: normal;
        // }
      `}
  />
);

export default Fonts;
