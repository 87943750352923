"use client";

import { Box, Center, Spinner, Text } from "@chakra-ui/react";

export default function Loading() {
  // You can add any UI inside Loading, including a Skeleton.
  return (
    <Box>
      <Center>
        <Spinner size="xl" />
      </Center>
      <Text paddingTop="10" textAlign="center">
        ロード中...
      </Text>
    </Box>
  );
}
