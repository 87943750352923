"use client";

// これが元々 _app.tsx と _document.tsx だったものが統合されたやつ
import CSR from "@/components/CSR";
import { Box, ChakraProvider } from "@chakra-ui/react";
import Script from "next/script";
import Fonts from "./fonts";
import "./globals.css";
import "./i18n";
import { theme } from "./theme";

export default function RootLayout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="ja">
      <head>
        <title>Shisha Cafe tone. のチェックインページ</title>
        <meta name="description" content="Shisha Cafe tone. のチェックインページ" />
        <link rel="stylesheet" href="https://use.typekit.net/fdq5yun.css" />
        <link rel="apple-touch-icon" sizes="57x57" href="/favicons/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/favicons/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/favicons/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/favicons/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/favicons/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/favicons/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/favicons/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/favicons/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/favicons/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicons/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/favicons/manifest.json" />
      </head>
      <body>
        <ChakraProvider theme={theme}>
          <Fonts />
          <CSR>
            <Box bgColor="gray.100" minH="100vh" p="10" whiteSpace="pre-line">
              {children}
            </Box>
          </CSR>
        </ChakraProvider>
        <Script
          id="adobe-font"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: ` (function(d) {
            var config = {
              kitId: 'ltd5qkf',
              scriptTimeout: 3000,
              async: true
            },
            h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
          })(document);
          `,
          }}
        />
      </body>
    </html>
  );
}
